import GET_ARTICLES from '../class/getArticles';

const [d,mq] = [document,window.matchMedia( "(max-width: 1023px)" )];

class BLOCKS {
    constructor() {
        this.init();
    }

    init()
    {
        this.news('.sdgs-news__list[data-plugin="getArticles"]');
    }

    news(selector) {
        const news_block = new GET_ARTICLES(selector);
        news_block.makeItem = (item, content) => `
        <li class="sdgs-news-item">
        <a href="${item.href}" class="sdgs-news-item__link">
            <figure class="sdgs-news-item__img">
                <img src="${item.thumb}" alt="${item.title}" loading="lazy">
            </figure>
            <div class="sdgs-news-item__body">
                <span class="date">${item.date}</span>
                <span class="subject">${item.title.str_truncate(24)}</span>
                <span class="body">${item.body.str_truncate(24)}</span>
            </div>
        </a>
        </li>`;
        // news_block.makeItem = (item) => console.log(item);
        news_block.render();
    }
}
const domReady = (TPL) => {
};

export default function CORPORATE() {
    // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
    d.addEventListener('rwd002.beforeDomready', (e) => domReady(e.detail));
    $(() => {
        Promise.all([window.load['loadHtml']]).then(() => new BLOCKS());
    });
}